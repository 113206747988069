import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useViewMode } from '~/context/ViewModeContext';
import useOnClickOutside from '~/hooks/useOnClickOutside';
import BulletsIcon from '~/icons/BulletsIcon';
import ChevronDown from '~/icons/ChevronDown';
import DescriptionIcon from '~/icons/DescriptionIcon';

import styles from './SortButton.module.scss';

const ViewButton = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { setViewMode } = useViewMode();

  const componentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(componentRef, () => setShowDropdown(false));

  return (
    <div ref={componentRef} className={styles.sortHolder}>
      <button
        onClick={() => setShowDropdown((prev) => !prev)}
        className={classNames(styles.sortButton, {
          [styles.isActive]: !!showDropdown,
        })}
      >
        View
        <ChevronDown />
      </button>
      {!!showDropdown && (
        <div className={classNames(styles.dropdown, styles.dropdownView)}>
          <div className={styles.dropdownTitle}>View as:</div>

          <button
            onClick={() => {
              setViewMode('bullets');
              setShowDropdown(false);
            }}
            className={styles.dropdownButton}
          >
            <BulletsIcon />
            Bullets
          </button>
          <button
            onClick={() => {
              setViewMode('description');
              setShowDropdown(false);
            }}
            className={styles.dropdownButton}
          >
            <DescriptionIcon />
            Description
          </button>
        </div>
      )}
    </div>
  );
};

export default ViewButton;
